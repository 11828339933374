<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.envio_material.filtro.codigo')"
      :placeholder="$t('modulos.envio_material.filtro.codigo')"
      em-filtro
    />
    <input-date
      v-model="filtro.dataSolicitacao"
      :label="$t('modulos.envio_material.filtro.data_solicitacao')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
    />
    <input-select
      v-model="filtro.tipoEnvioMaterial"
      :options="opcoes.tipoEnvioMaterial"
      :label="
        $t('modulos.envio_material.filtro.logistica')
      "
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
      retornar-objeto
    />
    <input-select
      v-model="filtro.tipoEnvioId"
      :options="opcoes.tipoEnvio"
      :label="
        $t('modulos.envio_material.filtro.tipo_envio')
      "
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.nomeContratante"
      class="col-12 col-md-6"
      :label="$t('modulos.envio_material.filtro.contratante')"
      :placeholder="$t('modulos.envio_material.filtro.contratante')"
      em-filtro
    />
    <input-date
      v-model="filtro.dataAgendamento"
      :label="$t('modulos.envio_material.filtro.data_agendamento')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
    />
    <input-text
      v-model="filtro.ordemServico"
      class="col-12 col-md-6"
      :label="$t('modulos.envio_material.filtro.ordem_servico')"
      :placeholder="$t('modulos.envio_material.filtro.ordem_servico')"
      em-filtro
    />
    <input-select
      v-model="filtro.situacaoEnvioMaterial"
      :options="opcoes.EnumSituacaoEnvioMaterial"
      :label="$t('modulos.envio_material.filtro.situacao')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
      retornar-objeto
    />
  </div>
</template>
<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import TipoEnvioService from '@common/services/cadastros/TipoEnvioService';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';

export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      dataRecebimento: '',
      opcoes: {
        tipoEnvio: [],
        EnumSituacaoEnvioMaterial: [],
        tipoEnvioMaterial: [],
      },
    };
  },
  watch: {
    dataRecebimento: function () {
      this.filtro.dataRecebimento = this.dataRecebimento?.substr(0, 10);
    },
  },
  async mounted() {
    await this.buscarTiposEnvio();
    await this.buscarEnumSituacaoEnvioMaterial();
    await this.buscarTipoEnvioMateria();
  },
  methods: {
    buscarTiposEnvio: async function () {
      await TipoEnvioService.listar().then((res) => {
        this.opcoes.tipoEnvio = new DropdownModel(res.data.items);
      });
    },
    buscarEnumSituacaoEnvioMaterial: async function () {
      await EnumeradorService.buscar('EnumSituacaoEnvioMaterial').then((res) => {
        this.opcoes.EnumSituacaoEnvioMaterial = res;
      });
    },
    buscarTipoEnvioMateria: async function() {
      await EnumeradorService.buscar("EnumTipoEnvioMaterial").then((res) => {
        this.opcoes.tipoEnvioMaterial = res;
      });
    }
  },
};
</script>
