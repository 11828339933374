<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.envio_material.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.envio_material.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('EnvioMaterial', 'Inserir')"
      :sem-filtro="!buscarPermissao('EnvioMaterial', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('EnvioMaterial', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      filtros-com-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostrar-seletor="buscarPermissao('EnvioMaterial', 'Deletar')"
      @paginar="listarRegistros"
      @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <div class="d-flex" style="gap: 5px">
          <div style="width: 40px">
            <dropdown-padrao text color="secondary">
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>
              <div>
                <dropdown-padrao-item
                  v-if="buscarPermissao('EnvioMaterial', 'Editar')"
                  text
                  color="secondary"
                  :disabled="podeEditar(slotProps.data)"
                  @click="abrirEditar(slotProps.data)"
                >
                  {{ $t('geral.botoes.editar') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="
                    buscarPermissao('EnvioMaterial', 'Aprovar') &&
                    participanteLogadoConta
                  "
                  text
                  color="secondary"
                  :disabled="!podeAprovar(slotProps.data)"
                  @click="abrirAprovar(slotProps.data)"
                >
                  {{ $t('geral.botoes.aprovacao') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('EnvioMaterial', 'Visualizar')"
                  text
                  color="secondary"
                  @click="abrirVisualizar(slotProps.data)"
                >
                  {{ $t('geral.botoes.visualizar') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('EnvioMaterial', 'GerarQrCode')"
                  text
                  color="secondary"
                  @click="gerarQRCode(slotProps.data)"
                >
                  {{ $t('geral.botoes.gerar_qr_code') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('EnvioMaterial', 'Imprimir')"
                  @click="gerarPDFRelatorio(slotProps.data)"
                >
                  {{ $t('geral.botoes.imprimir') }}
                </dropdown-padrao-item>
                <dropdown-padrao-item
                  v-if="buscarPermissao('OrdemServico', 'Inserir')"
                  text
                  color="secondary"
                  :disabled="podeCriarOS(slotProps.data)"
                  @click="criarOS(slotProps.data)"
                >
                  {{ $t('geral.botoes.criar_os') }}
                </dropdown-padrao-item>
              </div>
            </dropdown-padrao>
          </div>
          <icone-padrao
            :icone="buscaStatusDados(slotProps.data.situacao)?.iconVue"
            :tooltip="buscaStatusDados(slotProps.data.situacao)?.text"
            :cor="buscaStatusDados(slotProps.data.situacao)?.color"
          />
          <icone-padrao
            v-if="participanteLogadoConta && !slotProps.data.flagAprovado"
            :icone="$vuetify.icons.values.alertWarningtOutline"
            cor="danger"
            :tooltip="$t('modulos.envio_material.aguardando_aprovacao')"
          />
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <modal-visualizacao-relatorio
      ref="modal-pdf"
      :titulo="$t('modulos.envio_material.titulos.imprimir')"
    />
  </div>
</template>

<script>
import helpers from '@/common/utils/helpers';
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import EnvioMaterialService from '@/common/services/cadastros/EnvioMaterialService.js';
import Filtro from './filtro.vue';
import Enums from '../../../../i18n/pt-BR/enums/';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { ModalVisualizacaoRelatorio } from '@components/misc';
import { mapGetters } from 'vuex';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
    ModalVisualizacaoRelatorio,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.envio_material.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'dataSolicitacao',
            text: this.$t('modulos.envio_material.tabela.data_solicitacao'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'tipoEnvioMaterial',
            text: this.$t('modulos.envio_material.tabela.logistica'),
            sortable: true,
            formatter: (v) => {
              if (v) return Enums.EnumTipoEnvioMaterial[v];
            },
          },
          {
            value: 'tipoEnvio.nome',
            text: this.$t('modulos.envio_material.tabela.tipo_envio'),
            sortable: true,
          },
          {
            value: 'contratante.nome',
            text: this.$t('modulos.envio_material.tabela.contratante'),
            sortable: true,
          },
          {
            value: 'solicitante.nome',
            text: this.$t('modulos.envio_material.tabela.solicitante'),
            sortable: true,
          },
          {
            value: 'dataAgendamento',
            text: this.$t('modulos.envio_material.tabela.data_agendamento'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'ordemServico.nome',
            text: this.$t('modulos.envio_material.tabela.ordem_servico'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      enumSituacaoEnvioMaterial: [],
      opcoes: {
        situacao: helpers.SituacaoEnvioMaterialEnum,
        EnumSituacaoEnvioMaterial: [],
      },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados.length > 0;
    },
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'EnvioMaterial', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  async mounted() {
    this.colunaOrdenada = 'DataSolicitacao';
    const promisesOpcoes = [];
    promisesOpcoes.push(this.buscarEnumSituacaoEnvioMaterial());
    promisesOpcoes.push(this.listarRegistros());
    await Promise.all(promisesOpcoes);

    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.envio_material.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    buscaStatusDados(status) {
      const item = this.opcoes.situacao.find((item) => status === item.value);
      if (item) item.iconVue = this.$vuetify.icons.values[item.icon];
      return item;
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      helpers.salvarStateFiltros(this, {
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        sort: this.colunaOrdenada,
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };

      const { enviomaterial } = this.$route.query;
      if (enviomaterial) parametros.codigo = enviomaterial;

      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      return EnvioMaterialService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    async excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        const listaIdExclusao = this.tabela.selecionados.map((s) => s.id);
        EnvioMaterialService.excluir(listaIdExclusao)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.envio_material.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo() {
      this.$router.push({ name: 'envio-material-novo' });
    },
    abrirEditar(item) {
      this.$router.push({
        name: 'envio-material-editar',
        params: { id: item.id },
      });
    },
    abrirAprovar(item) {
      this.$router.push({
        name: 'envio-material-editar',
        params: { id: item.id },
        query: { aprovar: true },
      });
    },
    podeEditar(item) {
      if (!item.flagAprovado && this.participanteLogadoConta) return true;
      return !(item.situacao !== 'Concluido' && item.situacao !== 'Cancelado');
    },
    podeAprovar(item) {
      return !item.flagAprovado && this.participanteLogadoConta;
    },
    podeCriarOS(item) {
      const situacoes = ['Pendente', 'Programado', 'Reagendado'];
      if(item.tipoEnvio.flagVisivelRoteirizacao && situacoes.includes(item.situacao)) return true;
      return !!item.ordemServico;
    },
    criarOS: function (item) {
      this.$router.push({
        name: 'ordem-servico-novo',
        query: { enviomaterial: item.id, retorno: 'envio-material' },
      });
    },
    abrirVisualizar(item) {
      this.$router.push({
        name: 'envio-material-visualizar',
        params: { id: item.id },
      });
    },
    buscarEnumSituacaoEnvioMaterial: async function () {
      return EnumeradorService.buscar('EnumSituacaoEnvioMaterial').then(
        (res) => {
          this.opcoes.EnumSituacaoEnvioMaterial = res;
        }
      );
    },
    gerarQRCode: async function (item) {
      if (item.possuiQRCode) return this.gerarPDFQRCode(item);
      this.$store.dispatch('Layout/iniciarCarregamento');
      await EnvioMaterialService.gerarQRCode(item.id)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.envio_material.gerar_qr_code_sucesso`)
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.listarRegistros();
          this.gerarPDFQRCode(item);
        });
    },
    gerarPDFQRCode: function (item) {
      this.$refs['modal-pdf'].abrirModal('qrcode', [
        { simbolo: 'id', valor: item.id },
      ]);
    },
    gerarPDFRelatorio: function (item) {
      this.$refs['modal-pdf'].abrirModal('envio-material', [
        { simbolo: 'id', valor: item.id },
      ]);
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
